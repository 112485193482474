import React from "react"
import Seo from "~common/components/Seo"
import { Box } from "~common/components/Primitives"
import PageHeader from "./components/PageHeader"
import PageBody from "./components/PageBody"

const Page = ({ children, seo, sx, ...rest }) => (
  <Box sx={{ ...sx }} {...rest}>
    <Seo {...seo} />
    {children}
  </Box>
)

Page.Header = PageHeader
Page.Body = PageBody

export default Page

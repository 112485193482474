import React from "react"
import { useLayoutContext } from "~layout/contexts/layout"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import Logo from "~common/components/Logo"
import Line from "~common/components/Line"
import DownArrows from "~common/components/DownArrows"

const PageHeader = ({ children, downArrows, sx, ...rest }) => {
  const { headerHeight } = useLayoutContext()

  return (
    <Box
      sx={{
        width: "100%",
        pt: headerHeight,
        minHeight: "100vh",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: `url("/almagesto-geometric-background.svg")`,
        backgroundPosition: "50% 50%",
        backgroundSize: "150vh",
        backgroundRepeat: "no-repeat",
        zIndex: 0,
        ...sx,
      }}
      {...rest}
    >
      <Logo
        color="softened-accent"
        sx={{
          position: "absolute",
          transform: "translateX(-50vw)",
          width: "100vh",
          height: "100vh",
          opacity: 0.2,
          zIndex: -1,
        }}
      />
      <Logo
        color="softened-secondary"
        sx={{
          position: "absolute",
          right: "-25vh",
          bottom: "8vh",
          width: "50vh",
          height: "50vh",
          opacity: 0.1,
          zIndex: -1,
        }}
      />
      <Box sx={{ flex: 1 }} />
      <Box
        sx={{
          flex: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: [4, 5],
        }}
      >
        {children}
      </Box>
      <Box sx={{ flex: 1, my: 5, display: "flex", alignItems: "center" }}>
        {downArrows && <DownArrows />}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          position: "absolute",
          bottom: 0,
          zIndex: 100,
          transform: "translateY(50%)translateY(-1px)",
        }}
      >
        <Line
          color="secondary"
          align="end"
          sx={{ width: [6, 6, 7], opacity: 0.7 }}
        />
        <Logo
          color="secondary"
          sx={{
            mx: [4, 5],
            width: [48, 64, 80],
            height: [48, 64, 80],
          }}
        />
        <Line
          color="secondary"
          align="start"
          sx={{ width: [6, 6, 7], opacity: 0.7 }}
        />
      </Box>
    </Box>
  )
}

PageHeader.Title = ({ children }) => (
  <Typography
    variant="heading1"
    sx={{
      textAlign: "center",
      color: "accent",
      textTransform: "uppercase",
      letterSpacing: "0.2em",
    }}
  >
    {children}
  </Typography>
)

PageHeader.Subtitle = ({ children }) => (
  <Typography variant="large" sx={{ textAlign: "center", my: [5, 4, 3] }}>
    {children}
  </Typography>
)

PageHeader.Description = ({ children }) => (
  <Typography
    variant="normal"
    sx={{
      textAlign: "center",
    }}
  >
    {children}
  </Typography>
)

export default PageHeader

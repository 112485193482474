import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        siteUrl
        titleTemplate
        title
        description
        author
        image
        logo
        motto
      }
    }
  }
`

const useSiteMetadata = () => {
  const data = useStaticQuery(query)
  return data.site.siteMetadata
}

export default useSiteMetadata

import React from "react"
import { useLayoutContext } from "~layout/contexts/layout"
import Page from "~common/components/Page"
import { Box } from "~common/components/Primitives"
import ProductDetails from "~shop/components/ProductDetails"

const ShopPage = () => {
  const { headerHeight, availableHeight } = useLayoutContext()

  return (
    <Page
      seo={{
        title: "Personalized Solar System Poster",
        description:
          "Celebrate your best memories with a unique astronomical poster, based on NASA ephemerides | Create your custom print!",
      }}
    >
      <Page.Body
        sx={{
          mt: headerHeight,
          minHeight: availableHeight,
        }}
      >
        <ProductDetails initialProductId="custom_poster_50x70cm">
          <ProductDetails.Heading>
            <ProductDetails.Title />
            <ProductDetails.Selector
              sx={{
                my: 4,
                width: ["100%", "100%", "100%", "auto"],
                display: ["flex", "flex", "flex", "none"],
                flexDirection: ["row", "row", "row", "column"],
                justifyContent: [
                  "space-between",
                  "space-between",
                  "space-between",
                  "auto",
                ],
              }}
              productsMap={{
                custom_poster_30x40cm: "small",
                custom_poster_50x70cm: "medium",
                custom_poster_61x91cm: "large",
              }}
            />
          </ProductDetails.Heading>
          <ProductDetails.Gallery>
            <ProductDetails.Image />
            <ProductDetails.ImageSelector />
          </ProductDetails.Gallery>
          <ProductDetails.Info>
            <ProductDetails.Title sx={{ display: ["none", "none", "block"] }} />
            <ProductDetails.Description sx={{ mt: [4] }}>
              Discover the Solar System as it appears from one of its planets
              during the period of your choice. Choose your planet, your
              meaningful dates, and create a unique piece of astronomical wall
              art. The perfect gift to celebrate something special here on the
              Earth through the planetary movements up there in the sky.
            </ProductDetails.Description>
            <ProductDetails.Selector
              sx={{
                mt: [4],
                width: ["100%", "100%", "100%", "auto"],
                display: ["none", "none", "none", "flex"],
                flexDirection: ["row", "row", "row", "column"],
                justifyContent: [
                  "space-between",
                  "space-between",
                  "space-between",
                  "auto",
                ],
              }}
              productsMap={{
                custom_poster_30x40cm: "small",
                custom_poster_50x70cm: "medium",
                custom_poster_61x91cm: "large",
              }}
            />
            <Box as="ul" sx={{ mt: [4] }}>
              <ProductDetails.Feature icon="shipping">
                FREE WORLDWIDE SHIPPING (7-14 days)
              </ProductDetails.Feature>
              <ProductDetails.Feature icon="ruler">
                <ProductDetails.Size />
              </ProductDetails.Feature>
              <ProductDetails.Feature icon="paper">
                Printed on Enhanced Matte Paper
              </ProductDetails.Feature>
              <ProductDetails.Feature icon="eco">
                Toxin-free, Biodegradable, Water-based Ink
              </ProductDetails.Feature>
            </Box>
            <Box as="ul" sx={{ mt: [4] }}>
              <ProductDetails.Note>no frame included</ProductDetails.Note>
            </Box>
            <ProductDetails.Description sx={{ mt: [4] }}>
              Using the Almagesto editor you can customize the{" "}
              <Box as="span" sx={{ fontWeight: 700 }}>
                reference planet
              </Box>
              , the{" "}
              <Box as="span" sx={{ fontWeight: 700 }}>
                starting and ending dates
              </Box>
              , the{" "}
              <Box as="span" sx={{ fontWeight: 700 }}>
                zoom level
              </Box>
              , the{" "}
              <Box as="span" sx={{ fontWeight: 700 }}>
                graphical theme
              </Box>{" "}
              and an optional{" "}
              <Box as="span" sx={{ fontWeight: 700 }}>
                personal message
              </Box>
              .
            </ProductDetails.Description>
            <ProductDetails.PriceTag sx={{ mt: [4] }} />
            <ProductDetails.Cta
              sx={{ my: [4], alignSelf: ["center", "center", "flex-start"] }}
            />
          </ProductDetails.Info>
        </ProductDetails>
      </Page.Body>
    </Page>
  )
}

export default ShopPage

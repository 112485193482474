import React from "react"
import { Box } from "~common/components/Primitives"

const PageBody = ({ children, sx, ...rest }) => (
  <Box sx={{ ...sx }} {...rest}>
    {children}
  </Box>
)

export default PageBody

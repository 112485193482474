import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import SchemaMarkup from "~common/components/SchemaMarkup"
import useSiteMetadata from "~common/hooks/useSiteMetadata"
import favicon from "./favicon.ico"

const Seo = ({
  title,
  titleTemplate,
  description,
  author,
  image,
  keywords,
  schemaMarkup,
  meta = [],
}) => {
  const { pathname } = useLocation()

  const {
    siteUrl,
    titleTemplate: defaultTitleTemplate,
    title: defaultTitle,
    description: defaultDescription,
    author: defaultAuthor,
    image: defaultImage,
    keywords: defaultKeywords,
  } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    titleTemplate: titleTemplate || defaultTitleTemplate,
    description: description || defaultDescription,
    image: image || defaultImage,
    author: author || defaultAuthor,
    keywords: keywords || defaultKeywords,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: `en`,
        }}
        title={seo.title}
        titleTemplate={titleTemplate}
        meta={[
          {
            name: `keywords`,
            content: seo.keywords,
          },
          {
            name: `description`,
            content: seo.description,
          },
          {
            property: `og:title`,
            content: seo.title,
          },
          {
            property: `og:description`,
            content: seo.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:url`,
            content: seo.url,
          },
          {
            property: `og:image`,
            content: seo.image,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: `@AlmagestoDesign`,
          },
          {
            name: `twitter:title`,
            content: seo.title,
          },
          {
            name: `twitter:site`,
            content: `@AlmagestoDesign`,
          },
          {
            name: `twitter:image`,
            content: seo.image,
          },
          {
            name: `twitter:description`,
            content: seo.description,
          },
          {
            name: `facebook-domain-verification`,
            content: `bf95tccv73cainbh0fnzdiulkcio02`,
          },
          {
            name: `p:domain_verify`,
            content: `1a4d84803794b7f39deb79e202553d48`,
          },
        ].concat(meta)}
      >
        <link rel="icon" href={favicon} />
      </Helmet>
      {schemaMarkup && <SchemaMarkup data={{ schemaMarkup }} />}
    </>
  )
}

export default Seo

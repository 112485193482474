import React from "react"
import { Box } from "~common/components/Primitives"
import Svg from "./down-arrows.svg"

const DownArrows = ({ color, sx, ...rest }) => {
  return (
    <Box
      framer
      initial="initial"
      animate="rest"
      variants={{
        initial: {
          y: 12,
          scaleY: 0.7,
        },
        rest: {
          y: 0,
          scaleY: 1,
          transition: {
            type: "spring",
            mass: 1,
            stiffness: 50,
            damping: 0,
          },
        },
      }}
      sx={{
        width: [4, 4, 24],
        height: [4, 4, 24],
        fill: color,
        ...sx,
      }}
      {...rest}
    >
      <Svg />
    </Box>
  )
}

DownArrows.defaultProps = {
  color: "secondary",
}

export default React.memo(DownArrows)
